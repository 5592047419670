import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { FaCogs } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledButtonDropdown, UncontrolledDropdown } from 'reactstrap';
import { thunk_reset_user } from '../../Action/action';
import { store } from '../../Store/store'

function TopMenuComponent(props) {
    const userInfo = store.getState();
    const history = useHistory();

   // console.log(userInfo)

    const Logout = () => {
        props.dispatch(thunk_reset_user({}));
        history.push("/");
    }

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow d-sm-none">

                </li>
                <li className="nav-item dropdown no-arrow mx-1">

                </li>
                <li className="nav-item dropdown no-arrow mx-1">

                </li>
                <div className="topbar-divider d-none d-sm-block"></div>

                <li className="nav-item dropdown no-arrow">

                    <Dropdown style={{ marginTop: '10px' }}>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            {userInfo.userData.User.First_Name} {userInfo.userData.User.Last_Name} &nbsp;&nbsp;<FaCogs></FaCogs>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => Logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </nav >

    )
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

export default connect(mapStateToProps)(TopMenuComponent);