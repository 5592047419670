import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { connect } from "react-redux";
import { Form, Button, FormGroup, Label, Alert } from "reactstrap";
import * as LoginService from "../../Services/Login/LoginService";
import useLoaderState from "../../Utility/useLoaderState";
import { useHistory } from "react-router-dom";
import { thunk_set_user } from "../../Action/action";

function LoginComponent(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [error, setErrorMessage] = useState("");
  const [IsLoading] = useLoaderState();
  const [otpSent, setOtpSent] = useState(false);
  const [disable, setDisable] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [disableOTP, setDisableOTP] = useState(false);
  const [timer, setTimer] = useState(60);
  const history = useHistory();

  // useEffect(() => {
  //   let intervalId;
  //   if (otpSent && timer > 0) {
  //     debugger;
  //     intervalId = setInterval(() => {
  //       setTimer((prevTimer) => prevTimer - 1);
  //     }, 1000);
  //   }
  //   return () => clearInterval(intervalId);
  // }, [otpSent, timer]);

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(interval);
            return 60; // Reset timer after 1 minute
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval); // Clear the interval if OTP is not sent or component unmounts
    }

    return () => clearInterval(interval); // Cleanup on unmount
  }, [otpSent, timer]);

  const handleLoginSubmit = () => {
    if (otp != "" && otp != undefined && otp != null) {
      setErrorMessage("");
      setDisable(true);
      //const user = { Email_Id: value.userName, Password: value.password };
      const user = { Mobile_No: phoneNumber, Login_OTP: otp };
      let result = LoginService.ValidateUser(user)
        .then((data) => {
          if (data.data.IsSuccess) {
            props.dispatch(thunk_set_user(data.data));
            toast.success("Login Successful");
            setTimeout(() => {
              history.push("/home");
            }, 1000);
          } else {
            setDisable(false);
            toast.error(data.data.Data);
            setErrorMessage(data.data.Data);
          }
        })
        .catch((error) => {
          setDisable(false);
          setErrorMessage(error.message);
          toast.error(error.message);
        });
    }
    else{
      toast.error("Please enter your OTP!");
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  const sendOTP = () => {
    if (phoneNumber != "" && phoneNumber != undefined && phoneNumber != null) {
      setDisableOTP(true);
      let response = LoginService.GetOTPByMobileNo(phoneNumber).then((data) => {
        if (data.data.IsSuccess) {
          toast.success(data.data.Data);
          setOtpSent(true);
          setTimeout(() => {
            setDisableOTP(false);
            setTimer(60); // Reset timer
            setOtpSent(false);
          }, 60000); // Disable for 1 minute (60000 milliseconds)
        } else {
          toast.error(data.data.Data);
          setDisableOTP(false);
        }
      });
    } else {
      toast.error("Mobile Number is required!");
    }
  };

  return (
    <div className="col-md-6 col-lg-7 d-flex align-items-center">
      <div className="card-body p-4 p-lg-5 text-black">
        <div>
          <div className="d-flex align-items-center mb-3 pb-1">
            <i className="fas fa-cubes fa-2x me-3"></i>
            <span
              className="h1 fw-bold mb-0"
              style={{ color: "#00bc7a" }}>
              HiCare Rule Engine
            </span>
          </div>
          <div className="form-outline mb-4">
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
          </div>
          {/* <div className="form-outline mb-4">
                        <label  className="form-label requiredfeild" > Email address</label>
                        <input type="email" id="form2Example17" {...register("userName", { required: true })} className="form-control form-control-lg" />
                        {errors.userName && <p style={{ color: 'red' }}>Email is Required</p>}
                    </div>

                    <div className="form-outline mb-4">
                        <label className="form-label requiredfeild"> Password</label>
                        <input type="password" id="form2Example27" {...register("password", { required: true })} className="form-control form-control-lg" />
                        {errors.password && <p style={{ color: 'red' }}>Password is Required</p>}
                    </div> */}

          {!otpSent ? (
            <>
              <div className="form-outline mb-4">
                <label className="form-label requiredfeild">
                  Mobile Number
                </label>
                <input
                  type="number"
                  id="form2Example17"
                  className="form-control form-control-lg"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </div>
              <div className="pt-1 mb-4">
                <button
                  className="btn btn-success btn-lg btn-block"
                  type="button"
                  disabled={disableOTP}
                  onClick={sendOTP}>
                  Get OTP
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="form-outline mb-4">
                <label className="form-label requiredfeild"> OTP</label>
                <input
                  type="number"
                  id="form2Example27"
                  className="form-control form-control-lg"
                  value={otp}
                  onChange={handleOTPChange}
                />
              </div>
              <p>Resend OTP in: {timer} seconds</p>
              <div className="pt-1 mb-4">
                <button
                  className="btn btn-success btn-lg btn-block"
                  type="button"
                  disabled={disable}
                  onClick={handleLoginSubmit}>
                  Login
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const matchStateToPros = (state) => {
  return {
    data: state,
  };
};
export default connect(matchStateToPros)(LoginComponent);
