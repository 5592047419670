import React from 'react'

function DashboardComponent() {
    return (
        <div style={{backgroundColor:"white"}}>
            <img src={"DashboardImage.jpg"} style={{ width: "80%", height: "50%", left:"100px",position:"relative" }} alt="HiCare" />
        </div>
    )
}

export default DashboardComponent
