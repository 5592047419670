import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Link, Switch } from 'react-router-dom';
import _loginLayout from './Layouts/_loginLayout';
import _dashboardLayout from './Layouts/_dashboardLayout';
import DashboardPage from './Pages/DashboardPage';
import LoginPage from './Pages/LoginPage';
import TagListPage from './Pages/TagListPage';
import TagDetailPage from './Pages/TagDetailPage';
import RuleEngineDetailPage from './Pages/RuleEngineDetailPage';
import RuleEngineListPage from './Pages/RuleEngineListPage';
import UserPage from './Pages/UserListPage';
import UserDetailPage from './Pages/UserDetailPage';
import NotificationLogPage from './Pages/NotificationLogPage';
import FailureLogPage from './Pages/FailureLogPage';

//import FileUploaderPage from './Components/User/FileUploaderComponent';

function App() {

  return (
    <Router>
      <Switch>
        <_loginLayout path="/" exact component={LoginPage}></_loginLayout>
        <_loginLayout path="/Login" exact component={LoginPage}></_loginLayout>
        <_dashboardLayout path="/tag" exact component={TagListPage}></_dashboardLayout>
        <_dashboardLayout path="/tag/add/:Tag_Id" component={TagDetailPage}></_dashboardLayout>
        <_dashboardLayout path="/tag/edit/:Tag_Id" component={TagDetailPage}></_dashboardLayout>
        <_dashboardLayout path="/home" exact component={DashboardPage}></_dashboardLayout>
        <_dashboardLayout path="/ruleengine/add/:RuleEngine_Id" exact component={RuleEngineDetailPage}></_dashboardLayout>
        <_dashboardLayout path="/ruleengine/edit/:RuleEngine_Id" exact component={RuleEngineDetailPage}></_dashboardLayout>
        <_dashboardLayout path="/ruleengine" exact component={RuleEngineListPage}></_dashboardLayout>
        <_dashboardLayout path="/user" exact component={UserPage}></_dashboardLayout>
        <_dashboardLayout path="/user/add/:User_Id" component={UserDetailPage}></_dashboardLayout>
        <_dashboardLayout path="/user/edit/:User_Id" component={UserDetailPage}></_dashboardLayout>
        <_dashboardLayout path="/notificationlog" exact component={NotificationLogPage}></_dashboardLayout>
        <_dashboardLayout path="/failurelog" exact component={FailureLogPage}></_dashboardLayout>
      </Switch>
    </Router>

  );
}

export default App;
