import React, { createContext, useContext, useState, useEffect } from 'react'
import { Button, Table, Alert } from 'reactstrap';
import { FaFileExcel, FaCheckCircle, FaTimesCircle, FaEdit, FaRegTrashAlt, FaTag } from 'react-icons/fa';
import * as tagService from '../../Services/Tag/TagService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import * as CommonVariable from '../../Utility/CommonVariables'
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState'

function TagListComponent() {
    const [isModelOpen, setModel] = useState(true);
    const [TagList, setTagList] = useState([]);
    const [selectedTagId, setTagId] = useState(0);
    const toggle = () => (setModel(!isModelOpen));
    const [error, setError] = useState("");
    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();
    const [IsLoading] = useLoaderState();

    useEffect(() => {
        GetAllTagList();
    }, [])

    const GetAllTagList = () => {
        setError('');
        tagService.GetAllTagList().then(data => {
            if (data.data.IsSuccess) {
                //console.log(data.data.Data)
                setTagList(data.data.Data);
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const editTag = (TagId) => {
        history.push('/tag/edit/' + parseInt(TagId));
    }

    const deleteTag = (TagId, Is_Active) => {
        setError('');
        var action = window.confirm('Do you want to delete Template?');
        if (action == true) {
            tagService.ActivateDeactiveTag(TagId, !Is_Active).then(data => {
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage);
                    GetAllTagList();
                } else {
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
    }

    const addNewTag = () => {
        history.push('/tag/add/' + 0);
    }

    const GetActiveTemplateReport = () => {
        // e.preventDefault();
        if (TagList!=null) {
            window.open(CommonVariable.API_DOMAIN + `TagMaster/GetActiveTemplateReport`, "_blank");
        }
        else {
            toast.error('No Record Found')
        }
    }

    return (
        <ContainerComponent Title="Templates" ButtonTitle="Template" Icon={<FaTag />} AddNewAction={addNewTag} ShowAction="true"
        DownloadBtnTitle="Download Template Report" BtnIcon={<FaFileExcel />} AddDownloadAction={ GetActiveTemplateReport} ShowDownloadAction="true"
        >
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {/* <button className="btn btn-primary float-right" onClick={(e) => { GetActiveTemplateReport(e) }} ><FaFileExcel /> Download Template Report </button> */}
            
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Template Name</th>
                        <th>Type</th>
                        <th>SMS</th>
                        <th>WhatsApp</th>
                        <th>Email</th>
                        <th>Notification</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        TagList.map((Tag, Index) => {
                            return (
                                <tr key={Index}>
                                    <td>{Tag.Id}</td>
                                    <td>{Tag.Tag_Name}</td>
                                    <td>{Tag.Tag_Type}</td>
                                    <td>
                                        {
                                            Tag.IsSendSMS ? 'Yes' : 'No'
                                        }
                                    </td>
                                    <td>
                                        {
                                            Tag.IsSendWhatsApp ? 'Yes' : 'No'
                                        }
                                    </td>
                                    <td>
                                        {
                                            Tag.IsSendEmail ? 'Yes' : 'No'
                                        }
                                    </td>
                                    <td>
                                        {
                                            Tag.IsSendNotification ? 'Yes' : 'No'
                                        }
                                    </td>
                                    <td>
                                        {
                                            Tag.Is_Active ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />
                                        }
                                    </td>
                                    <td>
                                        <FaEdit style={{ cursor: "pointer" }} onClick={
                                            () => { editTag(Tag.Id) }
                                        }></FaEdit>&nbsp;&nbsp;
                                        {
                                            Tag.Is_Active ? <FaRegTrashAlt style={{ color: 'green', cursor: "pointer" }} onClick={() => { deleteTag(Tag.Id, Tag.Is_Active) }} /> : <FaRegTrashAlt style={{ color: 'red', cursor: "pointer" }} onClick={() => { deleteTag(Tag.Tag_Id, Tag.Is_Active) }} />
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        TagList.length <= 0
                            ?
                            <tr>
                                <td colSpan="7">No Data Available</td>
                            </tr>
                            :
                            <></>
                    }
                </tbody>
            </Table>

        </ContainerComponent>
    )
}

export default TagListComponent
