import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables'

export const GetNotificationLogList = async () => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'NotificationLog/GetNotificationLogList', CommonVariable.API_HEADER)).then(response => response);
    return result;
}
export const GetFailureLogList = async () => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'NotificationLog/GetFailureLogList', CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetFilterSuccessNotificationLogList = async (startDate,endDate,triggerType) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `NotificationLog/GetFilterSuccessNotificationLogList?startDate=${startDate}&endDate=${endDate}&triggerType=${triggerType}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}
export const GetFilterFailureNotificationLogList = async (startDate,endDate,triggerType) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `NotificationLog/GetFilterFailureNotificationLogList?startDate=${startDate}&endDate=${endDate}&triggerType=${triggerType}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}
