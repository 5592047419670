import React, { useEffect, useRef, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ContainerComponent from "../Container/ContainerComponent";
import JoditEditor from "jodit-react";
import { FaInfo, FaPlus, FaUpload, FaTrashAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import * as AttributeService from "../../Services/Attribute/AttributeService";
import * as EmailService from "../../Services/Email/EmailService";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import * as CommonVariables from "../../Utility/CommonVariables";
import * as tagService from "../../Services/Tag/TagService";
import { useHistory } from "react-router-dom";
import ReactJson from "react-json-view";
import LoadingSpinnerComponent from "../Loader/LoadingSpinnerComponent";
import useLoaderState from "../../Utility/useLoaderState";
import { FilePicker } from "evergreen-ui";
// import copy from "copy-to-clipboard";

function TagDetailComponent(props) {
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm({ mode: "onChange" });
  const TagType = [
    { Key: "Account", Value: "Account" },
    { Key: "Activity", Value: "Activity" },
    { Key: "Task", Value: "Task" },
    { Key: "Order", Value: "Order" },
    { Key: "Complaint", Value: "Complaint" },
  ];
  const [Attribute, setAttribute] = useState([]);
  const [EmailList, setEmailList] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const templateType = watch("Tag_Type", "");
  const { Tag_Id } = useParams();
  const history = useHistory();
  const [error, setError] = useState("");
  const [isOpen, setToggle] = useState(false);
  const [sampleJson, setSampleJson] = useState("");
  const [IsLoading] = useLoaderState();

  const togglePopup = () => setToggle(!isOpen);

  const [SMSAttributevalue, setSMSAttributeValue] = useState("");
  const [WAAttributevalue, setWAAttributeValue] = useState("");
  const [EmailAttributevalue, setEmailAttributeValue] = useState("");

  var smsTextvalue = watch("SMS_Text", "");
  const waTextValue = watch("WhatsApp_Attribute", "");

  const isSendSMS = watch("IsSendSMS", false);
  const isSendWA = watch("IsSendWhatsApp", false);
  const isSendEmail = watch("IsSendEmail", false);

  const isButtonStatic = watch("IsButton_Static", false);
  const isImageStatic = watch("IsImage_Static", false);

  const [WAButtonParamList, setWAButtonParamList] = useState([]);
  const [BTNParamType, setBTNParamType] = useState([]);
  const [ShowNumberComponent, setShowNumberComponent] = useState(false);
  const [ShowLinkComponent, setShowLinkComponent] = useState(false);

  const [WALinkAttributeTypeParam, setWALinkAttributeTypeParam] = useState("");
  const [SelectedFileDetails, setSelectedFileDetails] = useState("");
  const [WhatsApp_Attachment, setWhatsApp_Attachment] = useState("");

  
  const [SelectedFileDetailsNotification, setSelectedFileDetailsNotification] = useState("");
  const isSendNotification = watch("IsSendNotification", false);
  const [Notification_Image, setNotification_Image] = useState("");

  const Document_URL =
    "https://s3.ap-south-1.amazonaws.com/hicare-others/301db000-3b1d-487e-8097-26d43af9a3d5.png";

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 300,
  };

  //Page Load
  useEffect(() => {
    if (Tag_Id > 0) {
      GetTagDetailsById();
    }
    LoadEmailIds();
  }, []);

  //Load Attributes
  useEffect(() => {
    if (
      templateType != null &&
      templateType != "" &&
      templateType != undefined
    ) {
      loadAttribute();
      loadTemplateSampleData();
    }
  }, [templateType]);

  const loadTemplateSampleData = () => {
    AttributeService.GetAttributeData(templateType)
      .then((data) => {
        if (data.data.IsSuccess) {
          setSampleJson(data.data.Data);
        } else {
          setSampleJson("");
        }
      })
      .catch((error) => {
        setSampleJson("");
      });
  };

  const GetTagDetailsById = () => {
    setError("");
    tagService
      .GetTagDetailsById(Tag_Id)
      .then((response) => {
        if (response.data.IsSuccess) {
          reset(response.data.Data);
          if (response.data.Data.IsSendEmail) {
            setContent(response.data.Data.Email_Text);
            editor.current.value = response.data.Data.Email_Text;
          }
          if (!response.data.Data.WhatsApp_Attachment != null) {
            setWhatsApp_Attachment(response.data.Data.WhatsApp_Attachment);
            setValue(
              "WhatsApp_Attachment",
              response.data.Data.WhatsApp_Attachment
            );
          }
          if (!response.data.Data.Notification_Image != null) {
            setWhatsApp_Attachment(response.data.Data.Notification_Image);
            setValue(
              "Notification_Image",
              response.data.Data.Notification_Image
            );
          }
          if (!response.data.Data.IsButton_Static) {
            if (
              response.data.Data.WAButtonParamList != undefined &&
              response.data.Data.WAButtonParamList != null &&
              response.data.Data.WAButtonParamList.length > 0
            ) {
              setWAButtonParamList(response.data.Data.WAButtonParamList);
            } else {
              setWAButtonParamList([]);
            }
          }
        } else {
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          toast.error("Your session is expired, Redirecting to Login page");
          history.push("");
        } else {
          setError(error.message);
          toast.error(error.message);
        }
      });
  };

  const loadAttribute = () => {
    if (templateType != null && templateType != "" && Attribute.length >= 0) {
      AttributeService.GetAttributeList(templateType)
        .then((data) => {
          if (data.data.IsSuccess) {
            setAttribute(data.data.Data);
          } else {
            setAttribute([]);
          }
        })
        .catch((error) => {
          setAttribute([]);
        });
    } else {
      setAttribute([]);
    }
  };

  const LoadEmailIds = () => {
    EmailService.GetEmailList()
      .then((data) => {
        if (data.data.IsSuccess) {
          setEmailList(data.data.Data);
        } else {
          setEmailList([]);
        }
      })
      .catch((error) => {
        setEmailList([]);
      });
  };

  const formSubmit = (value) => {
    setError("");

    if (checkDetails(value)) {
      !isButtonStatic
        ? (value.WhatsappButtonAttribute = WAButtonParamList)
        : (value.WhatsappButtonAttribute = []);

      if (parseInt(Tag_Id) > 0) {
        var action = window.confirm(
          "Do you really want to change message template ?"
        );
        if (action == true) {
          tagService
            .UpdateTagDetails(value)
            .then((data) => {
              //console.log(data)
              if (data.data.IsSuccess) {
                toast.success("Tag Detail " + data.data.ResponseMessage);
                history.push("/tag");
              } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
              }
            })
            .catch((error) => {
              if (error.message.includes("401")) {
                toast.error(
                  "Your session is expired, Redirecting to Login page"
                );
                history.push("");
              } else {
                setError(error.message);
                toast.error(error.message);
              }
            });
        }
      } else {
        tagService
          .SaveTagDetails(value)
          .then((data) => {
            if (data.data.IsSuccess) {
              toast.success("Tag Detail " + data.data.ResponseMessage);
              history.push("/tag");
            } else {
              setError(data.data.ResponseMessage);
              toast.error(data.data.ResponseMessage);
            }
          })
          .catch((error) => {
            if (error.message.includes("401")) {
              toast.error("Your session is expired, Redirecting to Login page");
              history.push("");
            } else {
              setError(error.message);
              toast.error(error.message);
            }
          });
      }
    } else {
      return false;
    }
  };

  const checkDetails = function (value) {
    if (isSendSMS) {
      if (
        value.SMS_Text == null ||
        value.SMS_Text == "" ||
        value.SMS_Channel == "" ||
        value.SMS_Channel == null
      ) {
        toast.error("SMS Text and SMS Channel is Mandatory!");
        return false;
      }
    } else {
      value.SMS_Text = "";
      value.SMS_Channel = "";
    }
    if (isSendWA) {
      if (
        value.WhatsApp_Attribute == null ||
        value.WhatsApp_Attribute == "" ||
        value.WhatsApp_Template == "" ||
        value.WhatsApp_Template == null
      ) {
        toast.error("WhatsApp Attribute and WhatsApp Template is Mandatory!");
        return false;
      }
    } else {
      value.WhatsApp_Attribute = "";
      value.WhatsApp_Template = "";
    }

    // if (isSendWA) {
    // if (!isButtonStatic) {
    //     if (WAButtonParamList == null || WAButtonParamList.length == 0) {
    //         toast.error('WhatsApp Button Attributes should have atleast one button')
    //         return false;
    //     }
    //     }
    //     else {
    //         setWAButtonParamList([]);
    //     }
    // }

    if (WAButtonParamList != null && !isButtonStatic) {
      if (
        WAButtonParamList.filter(
          (item) => item.Type == "" || item.AttributeName == ""
        ).length > 0
      ) {
        toast.error("WhatsApp Button Attributes Data cannot be empty");
        return false;
      }
    }

    if (isSendEmail) {
      if (
        value.Sender_Email_Id == null ||
        value.Sender_Email_Id == "" ||
        value.Email_Subject == "" ||
        value.Email_Subject == null ||
        editor.current.value == "" ||
        editor.current.value == null
      ) {
        toast.error(
          "Sender Email Id, Email Subject and Email Text is Mandatory!"
        );
        return false;
      }
      value.Email_Text = editor.current.value;
    } else {
      value.Sender_Email_Id = "";
      value.Email_Subject = "";
      value.Email_Text = "";
    }
    if (!isSendSMS && !isSendWA && !isSendEmail && !isSendNotification) {
      toast.error("Please select atleast 1 from (SMS/Email?WhatsApp/Notification)!");
      return false;
    }

    return true;
  };

  const setSMSAttribute = function (value) {
    setSMSAttributeValue(value);
    //console.log(value)
  };

  const addSMSAttributes = (e) => {
    e.preventDefault();
    var addedsmsattrib = "";
    if (
      SMSAttributevalue == undefined ||
      SMSAttributevalue == null ||
      SMSAttributevalue == ""
    ) {
      toast.error("Please Select an Attribute!");
      return false;
    } else {
      addedsmsattrib = "{{" + SMSAttributevalue + "}}";
    }

    if (
      smsTextvalue == undefined ||
      smsTextvalue == null ||
      smsTextvalue == ""
    ) {
      setValue("SMS_Text", addedsmsattrib);
    } else {
      setValue("SMS_Text", smsTextvalue + addedsmsattrib);
    }
    return true;
  };
  const setWAAttribute = function (value) {
    setWAAttributeValue(value);
    //console.log(value)
  };
  const addWAAttributes = (e) => {
    e.preventDefault();
    var addedwpattrib = "";
    if (
      WAAttributevalue == undefined ||
      WAAttributevalue == null ||
      WAAttributevalue == ""
    ) {
      toast.error("Please Select an Attribute!");
      return false;
    } else {
      addedwpattrib = "{{" + WAAttributevalue + "}}";
    }

    if (waTextValue == undefined || waTextValue == null || waTextValue == "") {
      setValue("WhatsApp_Attribute", addedwpattrib);
    } else {
      setValue("WhatsApp_Attribute", waTextValue + addedwpattrib);
      setContent(addedwpattrib);
    }
    return true;
  };

  const setEmailAttribute = function (value) {
    setEmailAttributeValue(value);
    //console.log(value)
  };

  const addEmailAttributes = (e) => {
    e.preventDefault();
    var addedemailattrib = "";
    if (
      EmailAttributevalue == undefined ||
      EmailAttributevalue == null ||
      EmailAttributevalue == ""
    ) {
      toast.error("Please Select an Attribute!");
      return false;
    } else {
      addedemailattrib = "{{" + EmailAttributevalue + "}}";
    }

    if (
      editor.current.value == undefined ||
      editor.current.value == null ||
      editor.current.value == ""
    ) {
      setContent(addedemailattrib);
    } else {
      setContent(editor.current.value + addedemailattrib);
    }
    return true;
  };

  const BackToList = (e) => {
    e.preventDefault();
    history.push("/tag");
  };

  const TestSMS = (value) => {
    if (checkDetails(value)) {
      tagService
        .TestSMS(value)
        .then((response) => {
          if (response.data.IsSuccess) {
            toast.success(response.data.ResponseMessage);
          } else {
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            toast.error("Your session is expired, Redirecting to Login page");
            history.push("");
          } else {
            setError(error.message);
            toast.error(error.message);
          }
        });
    }
    return false;
  };

  const TestEmail = (value) => {
    if (checkDetails(value)) {
      tagService
        .TestEmail(value)
        .then((response) => {
          if (response.data.IsSuccess) {
            toast.success(response.data.ResponseMessage);
          } else {
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            toast.error("Your session is expired, Redirecting to Login page");
            history.push("");
          } else {
            setError(error.message);
            toast.error(error.message);
          }
        });
    }
    return false;
  };

  const TestWhatsApp = (value) => {
    if (checkDetails(value)) {
      !isButtonStatic
        ? (value.WhatsappButtonAttribute = WAButtonParamList)
        : (value.WhatsappButtonAttribute = []);

      tagService
        .TestWA(value)
        .then((response) => {
          if (response.data.IsSuccess) {
            toast.success(response.data.ResponseMessage);
          } else {
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            toast.error("Your session is expired, Redirecting to Login page");
            history.push("");
          } else {
            setError(error.message);
            toast.error(error.message);
          }
        });
    }
    return false;
  };

  const TestNotification = (value) => {
    if (checkDetails(value)) {
      tagService
        .TestNotification(value)
        .then((response) => {
          if (response.data.IsSuccess) {
            toast.success(response.data.ResponseMessage);
          } else {
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            toast.error("Your session is expired, Redirecting to Login page");
            history.push("");
          } else {
            setError(error.message);
            toast.error(error.message);
          }
        });
    }
    return false;
  };

  const AddWAButtonParam = (e) => {
    if (
      WAButtonParamList != undefined &&
      WAButtonParamList != null &&
      WAButtonParamList.length < 2
    ) {
      setWAButtonParamList((param) => {
        return [
          ...param,
          { Id: WAButtonParamList.length + 1, Type: "", AttributeName: "" },
        ];
      });
    } else {
      toast.error("Attribute cannot be more than 2");
    }
  };

  const deleteParam = (index, AttributeId) => {
    setWAButtonParamList((param) => {
      return param.filter((item) => item.Id != AttributeId);
    });
  };

  const handleBTNParamType = (e, index) => {
    e.preventDefault();

    var tempParam = [...WAButtonParamList];
    tempParam[index].Type = e.target.value;
    tempParam[index].AttributeName = "";
    setBTNParamType(e.target.value);
    setWAButtonParamList(tempParam);
  };

  const handleParamAttributeName = (e, index) => {
    if (BTNParamType == "Number") {
      var tempParam = [...WAButtonParamList];
      tempParam[index].AttributeName = e.target.value;
      setWAButtonParamList(tempParam);
    } else {
      var tempParam = [...WAButtonParamList];
      tempParam[index].AttributeName = e.target.value;
      setWAButtonParamList(tempParam);
    }
  };
  const WA_Link_Attribute = function (value) {
    setWALinkAttributeTypeParam(value);
  };

  const WA_Image_Attribute = function (value) {
    setValue("WhatsApp_Attachment", "{{" + value + "}}");
  };

  const addWA_Link_Attributes = (e, index) => {
    e.preventDefault();

    var attributeValue = "";
    var tempParam = [...WAButtonParamList];

    if (
      WALinkAttributeTypeParam == undefined ||
      WALinkAttributeTypeParam == null ||
      WALinkAttributeTypeParam == ""
    ) {
      toast.error("Please Select an Attribute!");
      return false;
    } else {
      attributeValue = "{{" + WALinkAttributeTypeParam + "}}";
    }

    if (
      tempParam[index].AttributeName == undefined ||
      tempParam[index].AttributeName == null ||
      tempParam[index].AttributeName == ""
    ) {
      tempParam[index].AttributeName = attributeValue;
    } else {
      tempParam[index].AttributeName += attributeValue;
    }
    setWAButtonParamList(tempParam);
    return true;
  };

  const FileUploadToS3 = (e) => {
    e.preventDefault();

    if (SelectedFileDetails != null && SelectedFileDetails != "") {
      var formdata = new FormData();
      formdata.append("Myfile", SelectedFileDetails[0]);

      tagService
        .FileUploadToS3(formdata)
        .then((response) => {
          if (response.data.IsSuccess) {
            toast.success(response.data.ResponseMessage);
            setWhatsApp_Attachment(response.data.Data);
            setValue("WhatsApp_Attachment", response.data.Data);
          } else {
            setWhatsApp_Attachment("");
            setValue("WhatsApp_Attachment", "");
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            toast.error("Your session is expired, Redirecting to Login page");
            history.push("");
          } else {
            setError(error.message);
            toast.error(error.message);
          }
        });
    } else {
      toast.error("Please choose Image First!");
    }
  };
  const ClearSelectedFile = (e) => {
    e.preventDefault();
    setSelectedFileDetails("");
    setWhatsApp_Attachment("");
  };

  const ClearSelectedFileForNotification = (e) => {
    e.preventDefault();
    setSelectedFileDetailsNotification("");
    setNotification_Image("");
  };

  

  const FileUploadToS3ForNotification = (e) => {
    e.preventDefault();

    if (SelectedFileDetailsNotification != null && SelectedFileDetailsNotification != "") {
      var formdata = new FormData();
      formdata.append("Myfile", SelectedFileDetailsNotification[0]);
      tagService
        .FileUploadToS3(formdata)
        .then((response) => {
          if (response.data.IsSuccess) {
            //console.log("Notification Image", response);
            toast.success(response.data.ResponseMessage);
            setSelectedFileDetailsNotification(response.data.Data);
            setValue("Notification_Image", response.data.Data);
          } else {
            setWhatsApp_Attachment("");
            setValue("Notification_Image", "");
            toast.error(response.data.ResponseMessage);
          }
        })
        .catch((error) => {
          if (error.message.includes("401")) {
            toast.error("Your session is expired, Redirecting to Login page");
            history.push("");
          } else {
            setError(error.message);
            toast.error(error.message);
          }
        });
    } else {
      toast.error("Please choose Image First!");
    }
  };

  const allowonlynumber = (e) => {
    var a = [];
    var k = e.which;

    for (var i = 48; i < 58; i++) a.push(i);

    if (!(a.indexOf(k) >= 0)) e.preventDefault();
  };

  // const copyWhatsAppAttachment = (e) => {
  //     e.preventDefault();
  //     copy(WhatsApp_Attachment);
  //     toast.success(' WhatsApp Attachment URL Copied ')
  // }

  return (
    <ContainerComponent
      Title="Template Details"
      ShowAction="false">
      <LoadingSpinnerComponent></LoadingSpinnerComponent>
      <FormGroup className="float-right">
        <button
          type="submit"
          disabled={IsLoading}
          className="btn btn-success"
          onClick={handleSubmit(formSubmit)}>
          {Tag_Id > 0 ? "Update" : "Save"}
        </button>{" "}
        <button
          type="button"
          disabled={IsLoading}
          className="btn btn-secondary"
          onClick={(e) => {
            BackToList(e);
          }}>
          Cancel
        </button>
      </FormGroup>
      <Form autoComplete="off">
        {error ? <Alert color="danger">{error}</Alert> : <></>}
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <Row>
          <Col md="4">
            <FormGroup>
              <Label className="requiredfeild"> Template Name</Label>
              <input
                type="text"
                placeholder="Tag Name"
                className="form-control"
                {...register("Tag_Name", { required: true })}></input>
              {errors.Tag_Name && (
                <p style={{ color: "red" }}>Tag Name is Required</p>
              )}
            </FormGroup>
          </Col>
          <Col md="4">
            <Label className="requiredfeild"> Type</Label>
            <select
              className="form-control"
              {...register("Tag_Type", { required: true })}>
              <option value="">Select Type</option>
              {TagType.map((data, index) => {
                return (
                  <option
                    value={data.Key}
                    key={index}>
                    {data.Value}
                  </option>
                );
              })}
            </select>
            {errors.Tag_Type && (
              <p style={{ color: "red" }}>Tag Type is Required</p>
            )}
          </Col>
          {Tag_Id > 0 ? (
            <Col md="4">
              <FormGroup>
                <Label className="requiredfeild"> Is Active</Label>
                <FormGroup check>
                  <Label check>
                    <input
                      type="checkbox"
                      {...register("Is_Active")}></input>
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col md="4">
            <FormGroup check>
              <Label check> Send SMS </Label>{" "}
              <input
                type="checkbox"
                {...register("IsSendSMS")}></input>
            </FormGroup>
          </Col>
          {isSendSMS ? (
            <Col md="4">
              <FormGroup>
                <Label> Attributes</Label>{" "}
                {templateType != null && templateType != "" ? (
                  <FaInfo
                    style={{
                      right: "170px",
                      position: "absolute",
                      top: "7px",
                      width: "12px",
                      height: "12px",
                    }}
                    onClick={() => togglePopup()}></FaInfo>
                ) : (
                  ""
                )}
                <Row>
                  <Col
                    md="10"
                    style={{ paddingRight: "5px" }}>
                    <select
                      className="form-control"
                      {...register("SMSAttribute")}
                      onChange={(e) => {
                        setSMSAttribute(e.target.value);
                      }}>
                      <option value="">Select Property</option>
                      {Attribute != null &&
                        Attribute.map((data, index) => {
                          return data.ChildProperties != null &&
                            data.ChildProperties.length > 0 ? (
                            <optgroup
                              label={data.Value}
                              key={index}>
                              {data.ChildProperties.map((child, inx) => {
                                return (
                                  <option
                                    value={child.Key}
                                    key={inx}>
                                    {child.Value}
                                  </option>
                                );
                              })}
                            </optgroup>
                          ) : (
                            <option
                              value={data.Key}
                              key={index}>
                              {data.Value}
                            </option>
                          );
                        })}
                    </select>
                    {/* { templateType!=null && templateType!="" ? <FaInfo onClick={() => togglePopup()}></FaInfo>:""} */}
                  </Col>
                  <Col
                    md="2"
                    style={{ paddingRight: "5px" }}>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={(e) => {
                        addSMSAttributes(e);
                      }}>
                      <FaPlus></FaPlus>
                    </button>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          {isSendSMS ? (
            <Col md="4">
              <FormGroup>
                <Label className="requiredfeild"> SMS Channel</Label>

                <select
                  className="form-control"
                  {...register("SMS_Channel")}>
                  <option value="">Select Channel</option>
                  {CommonVariables.SMS_CHANNEL.map((data, index) => {
                    return (
                      <option
                        value={data.Value}
                        key={index}>
                        {data.Key}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {isSendSMS ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <textarea
                  className="form-control"
                  cols="13"
                  rows="5"
                  {...register("SMS_Text")}></textarea>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendSMS ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup className="float-right">
                <button
                  type="button"
                  disabled={IsLoading}
                  className="btn btn-primary"
                  onClick={handleSubmit(TestSMS)}>
                  Test SMS
                </button>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <br />
        <Row>
          <Col md="4">
            <FormGroup check>
              <Label check> Send WA </Label>{" "}
              <input
                type="checkbox"
                {...register("IsSendWhatsApp")}></input>
            </FormGroup>
          </Col>
          {isSendWA ? (
            <Col md="4">
              <FormGroup>
                <Label> Attributes</Label>{" "}
                {templateType != null && templateType != "" ? (
                  <FaInfo
                    style={{
                      right: "170px",
                      position: "absolute",
                      top: "7px",
                      width: "12px",
                      height: "12px",
                    }}
                    onClick={() => togglePopup()}></FaInfo>
                ) : (
                  ""
                )}
                <Row>
                  <Col
                    md="10"
                    style={{ paddingRight: "5px" }}>
                    <select
                      className="form-control"
                      {...register("WhatsApp_Attribute_Name")}
                      onChange={(e) => {
                        setWAAttribute(e.target.value);
                      }}>
                      <option value="">Select Property</option>
                      {Attribute != null &&
                        Attribute.map((data, index) => {
                          return data.ChildProperties != null &&
                            data.ChildProperties.length > 0 ? (
                            <optgroup
                              label={data.Value}
                              key={index}>
                              {data.ChildProperties.map((child, inx) => {
                                return (
                                  <option
                                    value={child.Key}
                                    key={inx}>
                                    {child.Value}
                                  </option>
                                );
                              })}
                            </optgroup>
                          ) : (
                            <option
                              value={data.Key}
                              key={index}>
                              {data.Value}
                            </option>
                          );
                        })}
                    </select>
                    {/* { templateType!=null && templateType!="" ? <FaInfo onClick={() => togglePopup()}></FaInfo>:""} */}
                  </Col>
                  <Col
                    md="2"
                    style={{ paddingRight: "5px" }}>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={(e) => {
                        addWAAttributes(e);
                      }}>
                      <FaPlus></FaPlus>
                    </button>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          {isSendWA ? (
            <Col md="4">
              <FormGroup>
                <Label className="requiredfeild"> WA Template Name</Label>
                <input
                  type="text"
                  className="form-control"
                  {...register("WhatsApp_Template")}></input>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
        </Row>

        {isSendWA ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <textarea
                  className="form-control"
                  cols="13"
                  rows="5"
                  {...register("WhatsApp_Attribute")}></textarea>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendWA ? (
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label check> Is Static Image </Label>
              </FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <input
                  type="checkbox"
                  {...register("IsImage_Static")}></input>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendWA && !isImageStatic ? (
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label check> WhatsApp Image </Label>
              </FormGroup>
            </Col>
            <Col md="4">
              <input
                type="text"
                placeholder="Enter Image Url"
                readonly
                className="form-control"
                {...register("WhatsApp_Attachment")}
              />
            </Col>
            <Col md="4">
              <select
                className="form-control"
                onChange={(e) => {
                  WA_Image_Attribute(e.target.value);
                }}>
                <option value="">Select Property</option>
                {Attribute != null &&
                  Attribute.map((data, index) => {
                    return data.ChildProperties != null &&
                      data.ChildProperties.length > 0 ? (
                      <optgroup
                        label={data.Value}
                        key={index}>
                        {data.ChildProperties.map((child, inx) => {
                          return (
                            <option
                              value={child.Key}
                              key={inx}>
                              {child.Value}
                            </option>
                          );
                        })}
                      </optgroup>
                    ) : (
                      <option
                        value={data.Key}
                        key={index}>
                        {data.Value}
                      </option>
                    );
                  })}
              </select>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendWA && isImageStatic ? (
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label check> WhatsApp Image </Label>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <FilePicker
                  width={200}
                  placeholder="Select Image"
                  onChange={(files) => setSelectedFileDetails(files)}
                />
              </FormGroup>
            </Col>
            <Col
              md="1"
              style={{ left: "20px", position: "relative" }}>
              <FormGroup>
                <FaUpload
                  style={{ color: "green", cursor: "pointer" }}
                  onClick={(e) => FileUploadToS3(e)}
                />
              </FormGroup>
            </Col>
            {isImageStatic &&
            WhatsApp_Attachment != "" &&
            WhatsApp_Attachment != undefined &&
            WhatsApp_Attachment != null ? (
              <Col md="3">
                <FormGroup>
                  <input
                    type="hidden"
                    {...register("WhatsApp_Attachment")}
                    value={WhatsApp_Attachment}
                  />
                  <a
                    href={WhatsApp_Attachment}
                    target={"_blank"}>
                    {WhatsApp_Attachment.includes(".jpg") ||
                    WhatsApp_Attachment.includes(".jpeg") ||
                    WhatsApp_Attachment.includes(".png") ? (
                      <img
                        src={WhatsApp_Attachment}
                        style={{
                          width: "20%",
                          height: "20%",
                          position: "relative",
                        }}
                        alt="HiCare"
                      />
                    ) : (
                      <img
                        src={Document_URL}
                        style={{
                          width: "20%",
                          height: "20%",
                          position: "relative",
                        }}
                        alt="HiCare"
                      />
                    )}
                    &nbsp;&nbsp;
                  </a>
                  <MdCancel
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={(e) => ClearSelectedFile(e)}
                  />
                </FormGroup>
              </Col>
            ) : (
              ""
            )}
          </Row>
        ) : (
          ""
        )}
        {isSendWA ? (
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label check> Is Static Button </Label>
              </FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <input
                  type="checkbox"
                  {...register("IsButton_Static")}></input>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendWA && !isButtonStatic ? (
          <>
            <br />
            <Row>
              <Col md="1"></Col>
              <Col md="10">
                <div className="row">
                  <div className="card shadow mb-4">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-11">
                          <h6 className="w-75">Button Attributes</h6>
                        </div>
                        <div
                          className="col-md-1 float-right"
                          style={{ paddingRight: "2px" }}>
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={(e) => AddWAButtonParam(e)}
                            color="success">
                            <FaPlus />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {WAButtonParamList != null &&
                        WAButtonParamList.map((data, index) => {
                          return (
                            <div
                              className="row"
                              style={{ marginBottom: "2px" }}
                              key={index}>
                              <div className="col-md-2">
                                <Label className="requiredfeild">
                                  Atrribute {index + 1}
                                </Label>
                              </div>
                              <div className="col-md-2">
                                <select
                                  className="form-control"
                                  value={data.Type}
                                  onChange={(e) =>
                                    handleBTNParamType(e, index)
                                  }>
                                  <option value="">Select</option>
                                  {CommonVariables.BTN_ATTRIBUTE.map(
                                    (data, index) => {
                                      return (
                                        <option
                                          key={data.Value}
                                          value={data.Value}>
                                          {data.Key}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>

                              {data.Type == "Number" ? (
                                <div className="col-md-7">
                                  {" "}
                                  <input
                                    type="tel"
                                    pattern="[1-9][0-9]{9}"
                                    onKeyPress={(e) => allowonlynumber(e)}
                                    placeholder="Enter Text"
                                    className="form-control"
                                    value={data.AttributeName}
                                    onChange={(e) =>
                                      handleParamAttributeName(e, index)
                                    }
                                    required
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              {data.Type == "Link" ? (
                                <>
                                  <div className="col-md-3">
                                    <textarea
                                      type="text"
                                      rows="3"
                                      placeholder="Enter Text"
                                      className="form-control"
                                      value={data.AttributeName}
                                      onChange={(e) =>
                                        handleParamAttributeName(e, index)
                                      }
                                    />{" "}
                                  </div>
                                  <div className="col-md-3">
                                    <select
                                      className="form-control"
                                      onChange={(e) => {
                                        WA_Link_Attribute(e.target.value);
                                      }}>
                                      <option value="">Select Property</option>
                                      {Attribute != null &&
                                        Attribute.map((data, index) => {
                                          return data.ChildProperties != null &&
                                            data.ChildProperties.length > 0 ? (
                                            <optgroup
                                              label={data.Value}
                                              key={index}>
                                              {data.ChildProperties.map(
                                                (child, inx) => {
                                                  return (
                                                    <option
                                                      value={child.Key}
                                                      key={inx}>
                                                      {child.Value}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </optgroup>
                                          ) : (
                                            <option
                                              value={data.Key}
                                              key={index}>
                                              {data.Value}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <div className="col-md-1">
                                    <FaPlus
                                      onClick={(e) => {
                                        addWA_Link_Attributes(e, index);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div className="col-md-1">
                                <FaTrashAlt
                                  onClick={() => deleteParam(index, data.Id)}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        {isSendWA ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup className="float-right">
                <button
                  type="button"
                  disabled={IsLoading}
                  className="btn btn-primary"
                  onClick={handleSubmit(TestWhatsApp)}>
                  Test WhatsApp
                </button>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <br />
        <Row>
          <Col md="4">
            <FormGroup check>
              <Label check> Send Email </Label>{" "}
              <input
                type="checkbox"
                {...register("IsSendEmail")}></input>
            </FormGroup>
          </Col>
          {isSendEmail ? (
            <Col md="4">
              <FormGroup>
                <Label> Attributes</Label>{" "}
                {templateType != null && templateType != "" ? (
                  <FaInfo
                    style={{
                      right: "170px",
                      position: "absolute",
                      top: "7px",
                      width: "12px",
                      height: "12px",
                    }}
                    onClick={() => togglePopup()}></FaInfo>
                ) : (
                  ""
                )}
                <Row>
                  <Col
                    md="10"
                    style={{ paddingRight: "5px" }}>
                    <select
                      className="form-control"
                      {...register("EmailAttribute")}
                      onChange={(e) => {
                        setEmailAttribute(e.target.value);
                      }}>
                      <option value="">Select Property</option>
                      {Attribute != null &&
                        Attribute.map((data, index) => {
                          return data.ChildProperties != null &&
                            data.ChildProperties.length > 0 ? (
                            <optgroup
                              label={data.Value}
                              key={index}>
                              {data.ChildProperties.map((child, inx) => {
                                return (
                                  <option
                                    value={child.Key}
                                    key={inx}>
                                    {child.Value}
                                  </option>
                                );
                              })}
                            </optgroup>
                          ) : (
                            <option
                              value={data.Key}
                              key={index}>
                              {data.Value}
                            </option>
                          );
                        })}
                    </select>
                    {/* { templateType!=null && templateType!="" ? <FaInfo onClick={() => togglePopup()}></FaInfo> :""} */}
                  </Col>
                  <Col
                    md="2"
                    style={{ paddingRight: "5px" }}>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={(e) => {
                        addEmailAttributes(e);
                      }}>
                      <FaPlus></FaPlus>
                    </button>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          {isSendEmail ? (
            <Col md="4">
              <FormGroup>
                <Label className="requiredfeild">Select Sender</Label>
                <select
                  className="form-control"
                  {...register("Sender_Email_Id")}>
                  <option value="">Select Sender</option>
                  {EmailList != null &&
                    EmailList.map((data, index) => {
                      return (
                        <option
                          value={data.Id}
                          key={index}>
                          {data.Sender_Email_Id}
                        </option>
                      );
                    })}
                </select>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {isSendEmail ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <Label className="requiredfeild"> Email Subject </Label>
                <input
                  type="text"
                  className="form-control"
                  {...register("Email_Subject")}></input>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendEmail ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {isSendEmail ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup className="float-right">
                <button
                  type="button"
                  disabled={IsLoading}
                  className="btn btn-primary"
                  onClick={handleSubmit(TestEmail)}>
                  Test Email
                </button>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <br />
        <Row>
          <Col md="4">
            <FormGroup check>
              <Label check> Send Notification </Label>{" "}
              <input
                type="checkbox"
                {...register("IsSendNotification")}></input>
            </FormGroup>
          </Col>
          {isSendNotification ? (
            <Col md="4">
              <FormGroup>
                <Label className="requiredfeild">Notification Title</Label>
                <input
                  type="text"
                  className="form-control"
                  {...register("Notification_Title")}></input>
              </FormGroup>
            </Col>
          ) : (
            ""
          )}
          {isSendNotification ? (
            <Row>
              <Col md="4">
                <FormGroup inline></FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Label className="requiredfeild">Notification Text</Label>
                  <textarea
                    className="form-control"
                    cols="13"
                    rows="5"
                    {...register("Notification_Body")}></textarea>
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {isSendNotification ? (
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label check> Notification Image </Label>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <FilePicker
                  width={200}
                  placeholder="Select Image"
                  onChange={(files) => setSelectedFileDetailsNotification(files)}
                />
              </FormGroup>
            </Col>
            <Col
              md="1"
              style={{ left: "20px", position: "relative" }}>
              <FormGroup>
                <FaUpload
                  style={{ color: "green", cursor: "pointer" }}
                  onClick={(e) => FileUploadToS3ForNotification(e)}
                />
              </FormGroup>
            </Col>
            {isImageStatic &&
            Notification_Image != "" &&
            Notification_Image != undefined &&
            Notification_Image != null ? (
              <Col md="3">
                <FormGroup>
                  <input
                    type="hidden"
                    {...register("Notification_Image")}
                    value={Notification_Image}
                  />
                  <a
                    href={Notification_Image}
                    target={"_blank"}>
                    {Notification_Image.includes(".jpg") ||
                    Notification_Image.includes(".jpeg") ||
                    Notification_Image.includes(".png") ? (
                      <img
                        src={Notification_Image}
                        style={{
                          width: "20%",
                          height: "20%",
                          position: "relative",
                        }}
                        alt="HiCare"
                      />
                    ) : (
                      <img
                        src={Document_URL}
                        style={{
                          width: "20%",
                          height: "20%",
                          position: "relative",
                        }}
                        alt="HiCare"
                      />
                    )}
                    &nbsp;&nbsp;
                  </a>
                  <MdCancel
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={(e) => ClearSelectedFileForNotification(e)}
                  />
                </FormGroup>
              </Col>
            ) : (
              ""
            )}
          </Row>
        ) : (
          ""
        )}
          {isSendNotification ? (
            <Row>
              <Col md="4">
                <FormGroup inline></FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Label className="requiredfeild">Notification PageLink</Label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("Page_Link")}></input>
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Row>
        {isSendNotification ? (
          <Row>
            <Col md="4">
              <FormGroup inline></FormGroup>
            </Col>
            <Col md="8">
              <FormGroup className="float-right">
                <button
                  type="button"
                  disabled={IsLoading}
                  className="btn btn-primary"
                  onClick={handleSubmit(TestNotification)}>
                  Test Notification
                </button>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <FormGroup className="float-right">
          <button
            type="submit"
            className="btn btn-success"
            disabled={IsLoading}
            onClick={handleSubmit(formSubmit)}>
            {Tag_Id > 0 ? "Update" : "Save"}
          </button>{" "}
          <button
            type="button"
            disabled={IsLoading}
            className="btn btn-secondary"
            onClick={(e) => {
              BackToList(e);
            }}>
            Cancel
          </button>
        </FormGroup>
      </Form>

      <Modal isOpen={isOpen}>
        <ModalHeader
          charCode="x"
          toggle={() => togglePopup()}>
          Sample Data
        </ModalHeader>
        <ModalBody>
          <div style={{ overflowY: "scroll", maxHeight: "350px" }}>
            <ReactJson src={sampleJson} />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            disabled={IsLoading}
            onClick={(e) => togglePopup()}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </ContainerComponent>
  );
}

export default TagDetailComponent;
