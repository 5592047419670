import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables'

export const GetAllTagList = async () => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'TagMaster/GetTagMasterList', CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetTagDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `TagMaster/GetTagMasterById?tagId=${id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetTagDetailsByTagType = async (tagtype) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `TagMaster/GetTagMasterByTagType?tagtype=${tagtype}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const SaveTagDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/AddTagMaster', JSON.stringify(model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateTagDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/UpdateTagMaster',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactiveTag = async (tagId,IsActive) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `TagMaster/ActivateDeactivateTag?Id=${tagId}&IsActive=${IsActive}`,CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const TestSMS = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/TestSMS',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const TestWA = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/TestWA',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const TestEmail = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/TestEmail',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const FileUploadToS3 = async (image) => {
    console.log(image)
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/UploadImage',image, CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const TestNotification = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'TagMaster/TestNotification',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}