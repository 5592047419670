import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaInfo, FaPlus, FaRuler, FaTrashAlt } from 'react-icons/fa';
import { Col, Form, Row, FormGroup, Label, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody } from 'reactstrap'
import ContainerComponent from '../Container/ContainerComponent';
import * as CommonVariables from '../../Utility/CommonVariables'
import * as AttributeService from '../../Services/Attribute/AttributeService'
import * as tagservice from '../../Services/Tag/TagService'
import { useHistory, useParams } from 'react-router-dom'
import * as ruleService from '../../Services/Rule/RuleService';
import { Toaster, toast } from 'react-hot-toast';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { store } from '../../Store/store';
import ReactJson from 'react-json-view';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import useLoaderState from '../../Utility/useLoaderState';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function RuleEngineDetailComponent() {
    const userInfo = store.getState();
    const { register, formState: { errors }, setValue, getValues, reset, handleSubmit, watch } = useForm({ mode: "onChange" });
    const { register: ruleregister, getValues: rulegetValue, setValue: rulesetValue, reset: rulereset, setError: rulesetError, handleSubmit: rulehandleSubmit, formState: { errors: ruleerrors } } = useForm({ mode: "onBlur" });
    const rowStyle = { marginTop: "10px" };
    const childRowStyle = { marginTop: "5px" };
    const [filterData, setFilterData] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [tagtypeList, setTagTypeList] = useState([]);
    const history = useHistory();
    const { RuleEngine_Id } = useParams();
    const [error, setError] = useState("");
    const tagType = watch("TagType", "");
    const [ruleexpressiondata, setruleexpressiondata] = useState([]);
    const [isOpen, setToggle] = useState(false);
    const [isInfoOpen, setInfoToggle] = useState(false);
    const [showFaInfo, showSampleDataInfo] = useState(false);
    const [TemplateCount, showTemplateCount] = useState(false);
    const [ShowSampleData, setShowSampleData] = useState(false);
    const [Count_Of_Template, setCount_Of_Template] = useState("");
    const [conditionValue, setConditionValue] = useState("");
    const [triggertype, setTriggerType] = useState("");
    const [connection, setConnection] = useState(null);
    const [sampleJson, setSampleJson] = useState("");
    const [IsLoading] = useLoaderState();

    const [testRuleResponse, setTestRuleResponse] = useState({ smsText: "", emailText: "", waText: "", isSuccess: false, responseMessage: "" });
    useEffect(() => {
        if (RuleEngine_Id > 0) {
            GetRuleEngineById();
        }

        const connect = new HubConnectionBuilder()
            .withUrl(CommonVariables.SignalR_API_DOMAIN + "hubs/notifications")
            .withAutomaticReconnect()
            .build();

        connect.on("ReceiveMessage", (message) => {
            if (userInfo.userData.User.User_Id == message.userId) {
                setTestRuleResponse(message);
            }
        });

        setConnection(connect);

    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    //console.log("Started");
                })
                .catch((error) => console.log(error));
        }
    }, [connection]);

    //useEffect(() => {
    //    if (tagType != null && tagType != '' && tagType != undefined) {
    //        loadProperties(tagType);
    //        loadTagType(tagType);
    //    }
    //}, [tagType]);


    const togglePopup = () => {
        setTestRuleResponse([])
        setToggle(!isOpen)
    };
    const toggleInfoPopup = () => {
        setValue('SampleDataValue', '')
        setSampleJson("");
        setInfoToggle(!isInfoOpen)
    };



    const GetRuleEngineById = () => {
        setError('');
        ruleService.GetRuleEngineById(RuleEngine_Id).then(response => {
            if (response.data.IsSuccess) {
                reset(response.data.Data);
                loadPropertyAndTags(response.data.Data.TagType);
                setValue('SuccessEvent', response.data.Data.SuccessEvent);
            }
            else {
                toast.error(response.data.errorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const onCategoryChange = (e) => {
        //e.preventDefault();
        showTemplateCount(false);
        // showSampleDataInfo(true)
        // loadTemplateSampleData(e.target.value)
        loadProperties(e.target.value);
        loadTagType(e.target.value);
    }
    const onSuccessEventChange = () => {
        // e.preventDefault();
        var Template_Name = getValues('TagType')
        var SuccessEvent = getValues('SuccessEvent')
        ruleService.GetCountOfTemplate(Template_Name, SuccessEvent).then(data => {
            if (data.data.IsSuccess) {
                showTemplateCount(true)
                console.log(data.data.Data.Count_Of_Template);
                setCount_Of_Template(data.data.Data.Count_Of_Template);

            } else {
                showTemplateCount(false)
            }
        }).catch(error => {
            setPropertyList([]);
        })
    }





    const loadTemplateSampleData = () => {
        var templateType = getValues('TagType');
        var sampleValue = getValues('SampleDataValue');
        if (templateType != null && templateType != "" && sampleValue != null && sampleValue != "") {
            AttributeService.GetAttributeSampleDataByConditionValue(templateType, sampleValue).then(data => {
                if (data.data.IsSuccess) {
                    setShowSampleData(true);
                    setSampleJson(data.data.Data);
                }
                else {
                    setShowSampleData(false);
                    toast.error("No data found for " + templateType + " ( " + sampleValue + " )")
                    setSampleJson("");
                }
            }).catch(error => {
                setShowSampleData(false);
                toast.error("Something went wrong!")
                setSampleJson("");
            });
        }
        else {
            toast.error('Selection of Rule Category and Condition Value is Mandatory!')
        }
    }
    const loadPropertyAndTags = (type) => {
        loadProperties(type);
        loadTagType(type);
        onSuccessEventChange();
    }

    const loadProperties = (templateType) => {
        if (templateType != null && templateType != '' && templateType.length >= 0) {
            AttributeService.GetAttributeList(templateType).then(data => {
                if (data.data.IsSuccess) {
                    //console.log(data.data.Data);
                    setPropertyList(data.data.Data);
                } else {
                    setPropertyList([]);
                }
            }).catch(error => {
                setPropertyList([]);
            })
        } else {
            setPropertyList([]);
        }
    }

    const BackToList = (e) => {
        e.preventDefault();
        history.push('/ruleengine')
    }

    const loadTagType = (templateType) => {
        if (templateType != null && templateType != '' && templateType.length >= 0) {
            tagservice.GetTagDetailsByTagType(templateType).then(data => {
                if (data.data.IsSuccess) {
                    setTagTypeList(data.data.Data);
                    setValue("SuccessEvent", getValues("SuccessEvent"));
                    //console.log(data.data.Data)
                } else {
                    setTagTypeList([]);
                }
            }).catch(error => {
                setTagTypeList([]);
            })
        } else {
            setTagTypeList([]);
        }
    }

    const AddCondition = () => {

        setFilterData((data) => {
            return [...data, { Id: data.length + 1, Property: "", Condition: "", Value: "", Filter: "" }]
        })
        //console.log(filterData);
    }

    const deleteExpression = (index, id) => {
        setFilterData(filterData.filter(data => data.Id != id));

        if (filterData.length = 1) {
            setValue("Expression", "");
        }
    }

    const updateFieldValue = (e, index) => {
        //debugger;
        var tempFilter = filterData;
        tempFilter[index] = { ...tempFilter[index], [e.target.name]: e.target.value };
        setFilterData(tempFilter);
    }

    const generateExpression = (e) => {
        e.preventDefault();
        var rule_expression = "";

        var validation1 = filterData.filter((data, index) => data.Filter == "" && filterData[index + 1] != undefined && filterData[index + 1] != null);
        var validation2 = filterData.filter((data, index) => data.Property == "" || data.Property == undefined || data.Condition == "" || data.Condition == undefined || data.Value == "" || data.Value == undefined);

        if (validation1.length > 0 || validation2.length > 0) {
            toast.error('Property, Condition, Value and Filter are mandatory!')
            return false;
        }

        filterData.map((data, index) => {
            //rule_expression += data.Property + "" + data.Condition + " " + data.Value.replaceAll("\"", "\\\"") + " " + data.Filter + " ";
            if (rule_expression == "")
                rule_expression += data.Property + "" + data.Condition + " " + data.Value + " " + data.Filter;
            else
                rule_expression += " " + data.Property + "" + data.Condition + " " + data.Value + " " + data.Filter;
        })
        setValue("Expression", rule_expression);
    }

    const testRule = (e) => {
        e.preventDefault();
        const formValue = getValues();
        if (formValue.TagType == null || formValue.RuleName == null || formValue.Expression == null || formValue.SuccessEvent == null || formValue.ErrorMessage == null
            || formValue.TagType == "" || formValue.RuleName == "" || formValue.Expression == "" || formValue.SuccessEvent == "" || formValue.ErrorMessage == "") {
            toast.error('Rule Name, Rule Category, Rule Success Tag, Error Message and Rule Expression is Required!')
            return false;
        }
        else {
            togglePopup();
        }
    }

    const executeRule = (e) => {
        e.preventDefault();
        const formValue = getValues();
        formValue.ConditionValue = conditionValue;
        formValue.TriggerType = triggertype;
        formValue.Enabled = true;
        if (CheckDetails(formValue)) {
            ruleService.TestRuleEngine(formValue).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Rule executed successfully");
                } else {
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                toast.error(error.errorMessage);
            })
        }
    }

    const CheckDetails = (formValue) => {
        //console.log(formValue);
        if (triggertype == null || triggertype == "" || conditionValue == null || conditionValue == "") {
            toast.error('Trigger Type and Condition Value is Required!')
            return false;
        }
        else {
            return true;
        }
    }


    const formSubmit = (e) => {
        e.preventDefault()
        const value = getValues();
        console.log(value)
        if (parseInt(RuleEngine_Id) > 0) {
            var action = window.confirm('Do you really want to change rule ?');
            if (action == true) {
                ruleService.UpdateRuleEngine(value).then(data => {
                    //console.log(data)
                    if (data.data.IsSuccess) {
                        toast.success("Rule Engine " + data.data.ResponseMessage);
                        history.push('/ruleengine')
                    } else {
                        setError(data.data.ResponseMessage);
                        toast.error(data.data.ResponseMessage);
                    }
                }).catch(error => {
                    if (error.message.includes("401")) {
                        toast.error("Your session is expired, Redirecting to Login page")
                        history.push('')
                    }
                    else {
                        setError(error.message);
                        toast.error(error.message);
                    }
                })
            }
        } else {
            ruleService.SaveRuleEngine(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Rule Engine " + data.data.ResponseMessage);
                    history.push('/ruleengine')
                } else {
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            })
        }
    }
    return (
        <ContainerComponent Title='Template Details' ShowAction="false">
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <FormGroup className="float-right">
            <button type="button" disabled={IsLoading} className="btn btn-success" onClick={(e) => { formSubmit(e) }}>{RuleEngine_Id > 0 ? 'Update' : 'Save'}</button>
                {' '}<button type="button" disabled={IsLoading} className="btn btn-secondary" onClick={(e) => { BackToList(e) }} >Cancel</button>
            </FormGroup>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Form autoComplete="off">
                <Row>
                    <Col md="4">
                        <FormGroup>
                            <Label className="requiredfeild" >Rule Name</Label>
                            <input type="text" placeholder="Rule Name" className="form-control" {...register("RuleName", { required: true })}></input>
                            {errors.RuleName && <p style={{ color: 'red' }}>Rule Name is Required</p>}
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label className="requiredfeild" >Rule Category</Label>
                            <select className="form-control" {...register("TagType", { required: "Mandatory field" })} onChange={(e) => onCategoryChange(e)}>
                                <option value="">Select Category</option>
                                {
                                    CommonVariables.TagType.map((data, index) => {
                                        return (
                                            <option value={data.Value} key={index}>{data.Key}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.TagType && <p style={{ color: 'red' }}>Rule Category is Required</p>}
                        </FormGroup>
                    </Col>
                    {
                        RuleEngine_Id > 0 ?
                            <Col md="4">
                                <FormGroup >
                                    <Label > Is Active </Label> <input type="checkbox" {...register("Enabled")}></input>
                                </FormGroup>
                            </Col>
                            : ""}
                </Row>
                <Row style={rowStyle}>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" >Rule Success Message</Label> &nbsp;&nbsp;
                            {Count_Of_Template => 1 ? <a href="#" style={{ fontSize: "12px", right: "10px" }}> Tag used in other {Count_Of_Template} rule. </a> : ""}
                            <select className="form-control" {...register("SuccessEvent", { required: "Mandatory field" })}
                                onChange={onSuccessEventChange}>
                                <option value="">Select Tag</option>
                                {
                                    tagtypeList != null && tagtypeList.map((data, index) => {
                                        return (
                                            <option value={data.Tag_Name} key={index}>{data.Tag_Name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.TagType && <p style={{ color: 'red' }}>Rule Success Tag is Required</p>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label className="requiredfeild" >Error Message</Label>
                            <input type="text" placeholder="Error Message" className="form-control" {...register("ErrorMessage", { required: "Mandatory field" })}></input>
                            {errors.ErrorMessage && <p style={{ color: 'red' }}>Error Message is Required</p>}
                        </FormGroup>
                    </Col>
                </Row>

                <Row style={rowStyle}>
                    <Col md="12">
                        <FormGroup>
                            <Label className="requiredfeild" >Rule Expression</Label>
                            {/* {showFaInfo ? <FaInfo onClick={() => toggleInfoPopup()}></FaInfo> : ""} */}
                            <FaInfo onClick={() => toggleInfoPopup()}></FaInfo>
                            <textarea cols="12" rows="5" {...register("Expression", { required: "Mandatory field" })} className="form-control"></textarea>
                            {errors.Expression && <p style={{ color: 'red' }}>Expression is Required</p>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col md="12">
                        <div className="row">
                            <div className="card shadow mb-4">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-11">
                                            <h6 className="w-75">Rule Expressions</h6>
                                        </div>
                                        <div className="col-md-1 float-right" style={{ paddingRight: "2px" }}>
                                            <button type="button" disabled={IsLoading} className="btn btn-success btn-sm" onClick={(e) => AddCondition(e)} color="success"><FaPlus /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        filterData.map((data, index) => {
                                            return (
                                                <div className="row" style={childRowStyle} key={index}>
                                                    <div className="col-md-3">
                                                        <select className="form-control" name="Property" onChange={(e) => updateFieldValue(e, index)}>
                                                            <option value="">Select Property</option>
                                                            {
                                                                propertyList != null && propertyList.map((data, index) => {
                                                                    return data.ChildProperties != null && data.ChildProperties.length > 0 ?
                                                                        (
                                                                            <optgroup label={data.Value} key={index}>
                                                                                {
                                                                                    data.ChildProperties.map((child, inx) => {
                                                                                        return (
                                                                                            <option value={child.Value} key={inx}>{child.Value}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </optgroup>
                                                                        )
                                                                        :
                                                                        <option value={data.Value} key={index}>{data.Value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <select className="form-control" name="Condition" onChange={(e) => updateFieldValue(e, index)}>
                                                            <option value="">Select Condition</option>
                                                            <option value="==" key="Equal">Equal</option>
                                                            <option value="!=" key="Not Equal">Not Equal</option>
                                                            <option value=">" key="Greater Than">Greater Than</option>
                                                            <option value="<" key="Less Than">Less Than</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <input type="text" name="Value" placeholder="Value" className="form-control" onChange={(e) => updateFieldValue(e, index)}></input>
                                                        <span style={{ fontSize: "8px", color: "red" }}>Use " for string value</span>
                                                    </div>

                                                    {
                                                        filterData[index + 1] != undefined && filterData[index + 1] != null ?
                                                            (<div className="col-md-3">
                                                                <select className="form-control" name="Filter" onChange={(e) => updateFieldValue(e, index)}>
                                                                    <option value="">Select Filter</option>
                                                                    <option value="and" key="AND">AND</option>
                                                                    <option value="or" key="OR">OR</option>
                                                                </select>
                                                            </div>) : ""
                                                    }
                                                    <div className="col-md-1">
                                                        <FaTrashAlt onClick={() => deleteExpression(index, data.Id)} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                    <div className="row" style={{ textAlign: "center", marginTop: "10px" }}>
                                        <div className="col-md-12 col-md-offset-5">
                                            <button className="btn btn-success" disabled={IsLoading} onClick={(e) => { generateExpression(e) }}>
                                                Generate Expression
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <FormGroup className="float-right">
                    <button type="button" disabled={IsLoading} className="btn btn-success" onClick={(e) => { formSubmit(e) }}>{RuleEngine_Id > 0 ? 'Update' : 'Save'}</button>
                    {/* <button type="submit" disabled={IsLoading} className="btn btn-success" onClick={handleSubmit(formSubmitTest)}>{RuleEngine_Id > 0 ? 'Update' : 'Save'}</button> */}
                    {' '}<button type="button" disabled={IsLoading} className="btn btn-success" onClick={(e) => { testRule(e) }}>Test Rule</button>
                    {' '}<button type="button" disabled={IsLoading} className="btn btn-secondary" onClick={(e) => { BackToList(e) }} >Cancel</button>
                </FormGroup>
            </Form>
            <Modal isOpen={isOpen} modalTransition={{ timeout: 0 }}>
                <ModalHeader charCode="x" toggle={() => togglePopup()}>
                    Sample Data
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <FormGroup>
                                <Label>Condition Value</Label>
                                <input type="text" className="form-control" onChange={(e) => setConditionValue(e.target.value)}></input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Trigger Type</Label>
                                <input type="text" className="form-control" onChange={(e) => setTriggerType(e.target.value)}></input>
                            </FormGroup>
                        </Row>
                        <Row style={childRowStyle}>
                            <Col md="12">
                                <Card color="#4e73df">
                                    <CardHeader>SMS Text</CardHeader>
                                    <CardBody>
                                        {ReactHtmlParser(testRuleResponse.smsText)}
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                        <Row style={childRowStyle}>
                            <Col md="12">
                                <Card color="#4e73df">
                                    <CardHeader>WhatsApp Text</CardHeader>
                                    <CardBody>
                                        {testRuleResponse.waText}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={childRowStyle}>
                            <Col md="12">
                                <Card color="#4e73df">
                                    <CardHeader>Email Text</CardHeader>
                                    <CardBody>
                                        {ReactHtmlParser(testRuleResponse.emailText)}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-success" disabled={IsLoading} onClick={(e) => executeRule(e)}>Run Test</button>
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => togglePopup()}>Close</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isInfoOpen}>
                <ModalHeader charCode="x" toggle={() => toggleInfoPopup()}>
                    Sample Data
                </ModalHeader>
                <ModalBody>
                    <div >
                        <Label>Sample Data</Label>
                        <input type="text" placeholder="Enter Sample Value" className="form-control" {...register("SampleDataValue", { required: true })}></input>
                        {/* <input type="text" className="form-control" onChange={(e) => setConditionValue(e.target.value)}></input> */}
                    </div><br />

                    <div style={{ textAlign: "center" }}>
                        <button className="btn btn-success" disabled={IsLoading} onClick={(e) => loadTemplateSampleData(e)}> Get Sample Data</button>
                    </div><br />
                    {ShowSampleData ?
                        <div style={{ overflowY: "scroll", maxHeight: "350px" }}>
                            <ReactJson src={sampleJson} />
                        </div>
                        : ""
                    }                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" disabled={IsLoading} onClick={(e) => toggleInfoPopup()}>Close</button>
                </ModalFooter>
            </Modal>

        </ContainerComponent>
    )
}

export default RuleEngineDetailComponent
