import React, { createContext, useContext, useState, useEffect } from 'react'
import { Button, Table, Alert } from 'reactstrap';
import { FaPlus, FaCheckCircle, FaTimesCircle, FaEdit, FaRegTrashAlt, FaUser } from 'react-icons/fa';
import * as UserService from '../../Services/User/UserService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';

function UserListComponent() {
    const [isModelOpen, setModel] = useState(true);
    const [UserList, setUserList] = useState([]);
    const [selectedUserId, setUserId] = useState(0);
    const [error, setError] = useState("");
    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();

    useEffect(() => {
        GetAllUserList();
    }, [])

    const GetAllUserList = () => {
        setError('');
        UserService.GetAllUserList().then(data => {
            if (data.data.IsSuccess) {
                //console.log(data.data.Data)
                setUserList(data.data.Data);
            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const editUser = (User_Id) => {
        history.push('/User/edit/' + parseInt(User_Id));
    }

    const deleteUser = (UserId, Is_Active) => {
        setError('');
        var action = window.confirm('Do you want to delete User?');
        if (action == true) {
            UserService.ActivateDeactiveUser(UserId, !Is_Active).then(data => {
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage);
                    GetAllUserList();
                } else {
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
    }

    const addNewUser = () => {
        history.push('/User/add/' + 0);
    }

    return (
        <ContainerComponent Title="User" ButtonTitle="User" Icon={<FaUser />} AddNewAction={addNewUser} ShowAction="true">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>User Name</th>
                        <th>Email Id</th>
                        <th>Mobile No</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        UserList.map((User, Index) => {
                            return (
                                <tr key={Index}>
                                    <td>{User.User_Id}</td>
                                    <td>{User.First_Name} {User.Last_Name}</td>
                                    <td>{User.Email_Id}</td>
                                    <td>{User.Mobile_No}</td>
                                    <td>
                                        {
                                            User.Is_Active ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />
                                        }
                                    </td>
                                    <td>
                                        <FaEdit style={{ cursor: "pointer" }} onClick={
                                            () => { editUser(User.User_Id) }
                                        }></FaEdit>&nbsp;&nbsp;
                                        {
                                            User.Is_Active ? <FaRegTrashAlt style={{ color: 'green', cursor: "pointer" }} onClick={() => { deleteUser(User.User_Id, User.Is_Active) }} /> : <FaRegTrashAlt style={{ color: 'red', cursor: "pointer" }} onClick={() => { deleteUser(User.User_Id, User.Is_Active) }} />
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        UserList.length <= 0
                            ?
                            <tr>
                                <td colSpan="7">No Data Available</td>
                            </tr>
                            :
                            <></>
                    }
                </tbody>
            </Table>

        </ContainerComponent>
    )
}

export default UserListComponent
