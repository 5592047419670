import React from 'react'
import RuleEngineDetailComponent from '../Components/Rules/RuleEngineDetailComponent'

function RuleEngineDetailPage() {
    return (
        <RuleEngineDetailComponent></RuleEngineDetailComponent>
    )
}

export default RuleEngineDetailPage
