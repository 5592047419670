import React from 'react'
// import TagDetailComponent from '../Components/Tag/TagDetailComponent'
import TagListComponent from '../Components/Tag/TagListComponent'
function TagListPage() {
    return (
        // <TagDetailComponent></TagDetailComponent>
        <TagListComponent></TagListComponent>
    )
}

export default TagListPage
