import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables'

export const GetAllRuleEngineList = async () => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'RuleEngine/GetRuleEngineList', CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetRuleEngineById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `RuleEngine/GetRuleEngineById?Id=${id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const SaveRuleEngine = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'RuleEngine/AddRuleEngine', JSON.stringify(model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateRuleEngine = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'RuleEngine/UpdateRuleEngine',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const TestRuleEngine = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'RuleEngine/TestRuleEngine',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactiveRuleEngine = async (Id,IsActive) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `RuleEngine/ActivateDeactivateRuleEngine?Id=${Id}&enabled=${IsActive}`,CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const GetCountOfTemplate = async (tagType,successEvent) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `RuleEngine/GetCountOfTemplate?tagtype=${tagType}&successEvent=${successEvent}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}
