import React from 'react'
import DashboardComponent from '../Components/Dashboard/DashboardComponent'

function DashboardPage() {
    return (
        <div className="container-fluid">
            <DashboardComponent></DashboardComponent>
        </div>
    )
}

export default DashboardPage
