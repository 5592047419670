import React, { useEffect, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker';

function useLoaderState() {
    const { promiseInProgress } = usePromiseTracker();
    const [IsLoading, SetLoading] = useState(false);

    useEffect(() => {        
        SetLoading(promiseInProgress);
        
        return () => {
            SetLoading(false);
        }
    }, [promiseInProgress])

    return [IsLoading];
}

export default useLoaderState
