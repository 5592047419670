import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables'

export const GetEmailList = async () => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'Email/GetEmailList', CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetEmailById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `Email/GetEmailById?id=${id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}