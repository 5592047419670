// export const API_DOMAIN = "http://localhost:44562/api/";
// export const SignalR_API_DOMAIN = "http://localhost:44562/";
 export const SignalR_API_DOMAIN = "https://dashboardapi.hicare.in/sns/";
 export const API_DOMAIN = "https://dashboardapi.hicare.in/sns/api/";
export const API_HEADER = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

export const FILE_UPLOAD_API_HEADER = {
    headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    }
};

export const SMS_CHANNEL = [
    { Key: "NetCore", Value: "NetCore" },
    { Key: "Tubelight", Value: "Tubelight" }
];

export const TagType = [{ Key: "Account", Value: "Account" },{ Key: "Activity", Value: "Activity" },{ Key: "Task", Value: "Task" }, { Key: "Order", Value: "Order" }, { Key: "Complaint", Value: "Complaint" }]

export const DATA_TYPE = [
    { Key: "Boolean", Value: "Boolean" },
    { Key: "DateTime", Value: "DateTime" },
    { Key: "Number", Value: "Number" },
    { Key: "String", Value: "String" }
];

export const BTN_ATTRIBUTE = [
    { Key: "Link", Value: "Link" },
    { Key: "Number", Value: "Number" }
];
