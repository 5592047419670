import React from 'react'
import { Route,Redirect,useParams } from 'react-router-dom'
import { store } from '../Store/store'
import LeftMenuComponent from '../Components/Menu/LeftMenuComponent';
import TopMenuComponent from '../Components/Menu/TopMenuComponent';

const _dashboardLayout = ({ component: Component, ...rest }) => {
    const userInfo = store.getState();
    const isAuthenticated = userInfo.isAuthenticated;
    return (
        <Route {...rest} render={matchProps => (
            isAuthenticated === true ? (
                <div id="wrapper">
                    <LeftMenuComponent></LeftMenuComponent>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <TopMenuComponent></TopMenuComponent>
                            <Component {...matchProps} />
                        </div>
                    </div>
                </div>
            ) :
               (<Redirect to="/"></Redirect>)
        )}>
        </Route>
    )
}

export default _dashboardLayout
