import React from 'react'
import { Route } from 'react-router-dom'

const _loginLayout = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchprops => (
            <Component {...matchprops} />
        )}>
        </Route>
    )
};

export default _loginLayout
