import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariables from "../../Utility/CommonVariables";

export const GetAttributeList = async (type) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Attribute/GetAttributeList?type=${type}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetAttributeData = async (type) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Attribute/GetAttributeSampleData?type=${type}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const GetAttributeSampleDataByConditionValue = async (type,conditionValue) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Attribute/GetAttributeSampleDataByConditionValue?type=${type}&conditionValue=${conditionValue}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}