import React from 'react'
import { FaUser, FaWallet, FaMagic, FaTag } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function LeftMenuComponent() {
    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <Link to='/home'>
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div>
                <img src="hicare-logo.png" style={{ height: "50px", width: "115px", marginTop: "10px", marginLeft: "10px" }} alt="HiCare" ></img>
            </Link>

            <div className="sidebar-heading">

            </div>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">                                
                <li className="nav-item">
                    <Link to='/tag' className="nav-link collapsed"><FaTag /> {' '} Templates</Link>
                </li>
                <li className="nav-item">
                    <Link to='/ruleengine' className="nav-link collapsed"><FaMagic /> {' '} Rules</Link>
                </li>
                <li className="nav-item">
                    <Link to='/notificationlog' className="nav-link collapsed"><FaWallet /> {' '} Notification Log</Link>
                </li>
                <li className="nav-item">
                    <Link to='/failurelog' className="nav-link collapsed"><FaWallet /> {' '} Failure Log</Link>
                </li>
                <li className="nav-item">
                    <Link to='/user' className="nav-link collapsed"><FaUser /> {' '} Users</Link>
                </li>
            </ul>

        </ul>

    )
}

export default LeftMenuComponent
