import React, { createContext, useContext, useState, useEffect } from 'react'
import { Button, Table, Alert } from 'reactstrap';
import { FaTag, FaFileExcel, FaCheckCircle, FaTimesCircle, FaSearch } from 'react-icons/fa';
import * as notificationLogService from '../../Services/NotificationLog/NotificationLogService';
import ContainerComponent from '../Container/ContainerComponent';
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast';
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import * as moment from 'moment';
import * as CommonVariable from '../../Utility/CommonVariables';
import ReactTooltip from 'react-tooltip';


function NotificationLogListComponent() {
    const [NotificationLogList, setNotificationLogList] = useState([]);
    const [NotificationLogDropDownList, setNotificationLogDropDownList] = useState([]);
    const [FilterNotificationList, setFilterNotificationList] = useState([]);
    const [ShowFilterTable, setShowFilterTable] = useState(false);
    const [error, setError] = useState("");
    const history = useHistory();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedTriggerType, setSelectedValue] = useState("");

    useEffect(() => {
        GetNotificationLogList();
    }, [])

    const GetNotificationLogList = () => {
        setError('');
        notificationLogService.GetNotificationLogList().then(data => {
            if (data.data.IsSuccess) {
                //console.log(data.data.Data)
                setNotificationLogList(data.data.Data.listValue);
                setNotificationLogDropDownList(data.data.Data.dropdownList);

            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const DownloadNotificationLog = (e) => {
        e.preventDefault();
        if (moment(endDate) >= moment(startDate)) {
            window.open(CommonVariable.API_DOMAIN + `NotificationLog/GetSuccessLogHistoryBetweenDate?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`, "_blank");
        }
        else {
            toast.error('End Date should be greater than or equal to the Start Date')
        }
    }
    const SearchSelectedValue = (e) => {
        e.preventDefault();
        if (selectedTriggerType != "") {
            if (selectedTriggerType == "All") {
                setShowFilterTable(false);
                GetNotificationLogList();
            }
            else {

                setFilterNotificationList(NotificationLogList.filter(item => item.Trigger_Type == selectedTriggerType))
                setShowFilterTable(true);
                console.log(NotificationLogList.filter(item => item.Trigger_Type == selectedTriggerType))

            }
        }
        else
            toast.error("Select Trigger Type First");
    }

    const SearchSelectedValueByDate = (e) => {
        e.preventDefault();
        setError('');
        if (moment(endDate) >= moment(startDate)) {
            notificationLogService.GetFilterSuccessNotificationLogList(startDate, endDate, selectedTriggerType).then(data => {
                if (data.data.IsSuccess) {
                    setNotificationLogList(data.data.Data.listValue);
                    setNotificationLogDropDownList(data.data.Data.dropdownList);
                    setSelectedValue('');
                    // setFilterNotificationList(data.data.Data)
                    // setShowFilterTable(true);

                } else {
                    setNotificationLogList([]);
                    setNotificationLogDropDownList([]);
                    setSelectedValue('');
                    // setFilterNotificationList([]);
                    // setShowFilterTable(false);
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        } else {
            toast.error('End Date should be greater than or equal to the Start Date')
        }
    }




    return (
        <ContainerComponent Title="NotificationLogs" Icon={<FaTag />} ShowAction="false">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <ReactTooltip effect="solid" />
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Row>
                <Col md="3">
                    Start Date : <DatePicker
                        onChange={setStartDate}
                        value={startDate}
                        clearIcon={null}
                        showDateSelect
                        format="dd-MM-yyyy"
                    />
                </Col>
                <Col md="3">
                    End Date : <DatePicker
                        onChange={setEndDate}
                        value={endDate}
                        clearIcon={null}
                        showDateSelect
                        format="dd-MM-yyyy"
                    />
                </Col>
               
                <Col md="3">
                    <select className="form-control" onChange={(e) => {
                        setSelectedValue(e.target.value)
                    }}>
                        <option value="">All</option>
                        {
                            NotificationLogDropDownList != null && NotificationLogDropDownList.map((data, index) => {
                                return (
                                    <option value={data.Trigger_Type} key={index}>{data.Trigger_Type}</option>
                                )
                            })
                        }
                    </select>
                </Col>
                <Col md="1">
                    <button className="btn btn-primary" onClick={(e) => { SearchSelectedValueByDate(e) }} ><FaSearch /> </button>
                </Col>
                <Col md="2">
                    <button className="btn btn-primary" onClick={(e) => { DownloadNotificationLog(e) }} ><FaFileExcel />  Download</button>
                </Col>
            </Row>
            <br />
            <Table>
                <thead>
                    <tr>
                        <th>Triggered On</th>
                        <th>Name</th>
                        <th>Order No</th>
                        <th>Trigger Type</th>
                        <th>Rule Event</th>
                        <th>SMS | WA |Email</th>
                    </tr>
                </thead>
                {/* {
                    ShowFilterTable ?
                        <tbody>
                            {
                                FilterNotificationList.map((NotificationLog, Index) => {
                                    return (
                                        <tr key={Index}>
                                            <td>{NotificationLog.Created_On}</td>
                                            <td>{NotificationLog.Name}</td>
                                            <td>{NotificationLog.Order_No}</td>
                                            <td>{NotificationLog.Trigger_Type}</td>
                                            <td>{NotificationLog.RuleEvent}</td>
                                            <td>
                                                {NotificationLog.SMS_Sent == 1 ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />} | {' '}
                                                {NotificationLog.WA_Sent == 1 ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />} | {' '}
                                                {NotificationLog.Email_Sent == 1 ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            {
                                NotificationLogList.length <= 0
                                    ?
                                    <tr>
                                        <td colSpan="7">No Data Available</td>
                                    </tr>
                                    :
                                    <></>
                            }
                        </tbody>
                        : */}
                         <tbody>
                            {
                                NotificationLogList.map((NotificationLog, Index) => {
                                    return (
                                        <tr key={Index}>
                                            <td>{NotificationLog.Created_On}</td>
                                            <td>{NotificationLog.Name}</td>
                                            <td>{NotificationLog.Order_No}</td>
                                            <td>{NotificationLog.Trigger_Type}</td>
                                            <td>{NotificationLog.RuleEvent}</td>
                                            <td>
                                                {NotificationLog.SMS_Sent == 1 ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />} | {' '}
                                                {NotificationLog.WA_Sent == 1 ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />} | {' '}
                                                {NotificationLog.Email_Sent == 1 ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            {
                                NotificationLogList.length <= 0
                                    ?
                                    <tr>
                                        <td colSpan="7">No Data Available</td>
                                    </tr>
                                    :
                                    <></>
                            }
                        </tbody>
                        {/* } */}
            </Table>

        </ContainerComponent>
    )
}

export default NotificationLogListComponent
