import React, { createContext, useContext, useState, useEffect } from 'react'
import { Button, Table, Alert } from 'reactstrap';
import { FaFileExcel, FaCheckCircle, FaTimesCircle, FaEdit, FaRegTrashAlt, FaRuler } from 'react-icons/fa';
import * as ruleService from '../../Services/Rule/RuleService';
import ContainerComponent from '../Container/ContainerComponent';
import { Toaster, toast } from 'react-hot-toast';
import * as CommonVariable from '../../Utility/CommonVariables'
import LoadingSpinnerComponent from '../Loader/LoadingSpinnerComponent';
//import { MDBDataTable } from 'mdbreact';
import { useHistory, useParams } from 'react-router-dom'

function RuleEngineListComponent() {
    const [isModelOpen, setModel] = useState(true);
    const [RuleEngineList, setRuleEngineList] = useState([]);
    const [selectedRuleEngineId, setRuleEngineId] = useState(0);
    const toggle = () => (setModel(!isModelOpen));
    const [error, setError] = useState("");
    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();

    useEffect(() => {
        GetAllRuleEngineList();
    }, [])

    const GetAllRuleEngineList = () => {
        setError('');
        setRuleEngineList([]);
        ruleService.GetAllRuleEngineList().then(data => {
            if (data.data.IsSuccess) {
                setRuleEngineList(data.data.Data);

            } else {
                setError(data.data.ResponseMessage);
                toast.error(data.data.ResponseMessage);
                if (data.data.ResponseMessage == 'List Not Found.')
                    setRuleEngineList([]);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const editRuleEngine = (RuleEngineId) => {
        history.push('/ruleengine/edit/' + parseInt(RuleEngineId));
    }

    const deleteRuleEngine = (RuleEngineId, Enabled) => {
        setError('');
        var action = window.confirm(Enabled ? ' Do you want to InActive Rule?' : 'Do you want to Active Rule?');
        if (action == true) {
            ruleService.ActivateDeactiveRuleEngine(RuleEngineId, !Enabled).then(data => {
                if (data.data.IsSuccess) {
                    toast.success(data.data.ResponseMessage);
                    GetAllRuleEngineList();
                } else {
                    GetAllRuleEngineList();
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            });
        }
    }

    const addNewRuleEngine = () => {
        history.push('/ruleengine/add/' + 0);
    }

    const GetActiveRuleReport = () => {

        if (RuleEngineList != null) {
            window.open(CommonVariable.API_DOMAIN + `RuleEngine/GetActiveRuleReport`, "_blank");
        }
        else {
            toast.error('No Record Found')
        }
    }

    return (

        <ContainerComponent Title="Rules" ButtonTitle="Rule" Icon={<FaRuler />} AddNewAction={addNewRuleEngine} ShowAction="true"
            DownloadBtnTitle="Download Rule Report" BtnIcon={<FaFileExcel />} AddDownloadAction={GetActiveRuleReport} ShowDownloadAction="true"
        >
            {/* <MDBDataTable striped bordered hover data={datatableProps} /> */}

            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {/* <button className="btn btn-primary" onClick={(e) => { GetActiveRuleReport(e) }} ><FaFileExcel /> Download Rule Report </button> */}
            <Table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Rule Name</th>
                        <th>Rule Category</th>
                        <th>Template Name</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        RuleEngineList.map((RuleEngine, Index) => {
                            return (
                                <tr key={Index}>
                                    <td>{RuleEngine.Id}</td>
                                    <td>{RuleEngine.RuleName}</td>
                                    <td>{RuleEngine.TagType}</td>
                                    <td>{RuleEngine.SuccessEvent}</td>
                                    <td>
                                        <FaTimesCircle style={{ color: RuleEngine.Enabled ? 'green' : 'red' }} />
                                    </td>
                                    <td>
                                        <FaEdit style={{ cursor: "pointer" }} onClick={
                                            () => { editRuleEngine(RuleEngine.Id) }
                                        }></FaEdit>&nbsp;&nbsp;

                                        <FaRegTrashAlt style={{ color: RuleEngine.Enabled ? 'green' : 'red' }} onClick={() => { deleteRuleEngine(RuleEngine.Id, RuleEngine.Enabled) }} />

                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        RuleEngineList.length <= 0
                            ?
                            <tr>
                                <td colSpan="7">No Data Available</td>
                            </tr>
                            :
                            <></>
                    }
                </tbody>
            </Table>


        </ContainerComponent>
    )
}

export default RuleEngineListComponent
