import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import * as CommonVariable from '../../Utility/CommonVariables'

export const GetAllUserList = async () => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + 'User/GetUserList', CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const GetUserDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariable.API_DOMAIN + `User/GetUserById?UserId=${id}`, CommonVariable.API_HEADER)).then(response => response);
    return result;
}

export const SaveUserDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'User/SaveUser', JSON.stringify(model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const UpdateUserDetails = async (model) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + 'User/UpdateUser',JSON.stringify( model), CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactiveUser = async (UserId,IsActive) => {
    const response = await trackPromise(axios.post(CommonVariable.API_DOMAIN + `User/ActivateDeactivateUser?Id=${UserId}&IsActive=${IsActive}`,CommonVariable.API_HEADER))
        .then(response => response);
    return response;
}


